import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCreditCard, faScaleBalanced, faFileInvoice, faMoneyBill, faFilterCircleDollar } from "@fortawesome/free-solid-svg-icons";

function Sidebar(props) {
  const location = useLocation();
  return (
    <div
      className={`sidebar bg-[#17171B] fixed h-[100vh] flex flex-col items-center transition-width duration-500 ${
        props.isSidebarOpen ? "w-[280px] md:w-[280px]" : "w-[0px] md:w-[80px]"
      }`}
    >
      <div className="mt-[35px] mb-[70px]">
        {props.isSidebarOpen ? (
          <img
            src={process.env.PUBLIC_URL + "/image/logo.png"}
            alt="logo"
            className="w-[140px]"
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/image/favicon.png"}
            alt="logo"
            className="w-[50px]"
          />
        )}
      </div>
      <div className="overflow-y-scroll">
        <ul className="flex flex-col gap-[10px]">
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/dashboard")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/dashboard">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/dashboard.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="dashboard icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Dashboard
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/expense")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/expenses">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/expenses.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="dashboard icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Expenses
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/business-mileage")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/mileage">
              <div className="flex flex-row gap-[12px]">
                <FontAwesomeIcon
                  icon={faCar}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="mileage icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Mileage
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/customer")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/customers">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/customers.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="dashboard icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Customers
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/opportunity")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/opportunity">
              <div className="flex flex-row gap-[12px]">
                <FontAwesomeIcon
                  icon={faFilterCircleDollar}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="opportunity icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Opportunity
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/drawings")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/drawings">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/drawings.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="drawings icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Drawings
                </span>
              </div>
            </Link>
          </li>
          <li className="hover:bg-white px-[16px] py-[10px] hover:rounded-[12px]">
            <Link to="">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/jobs.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="jobs icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Jobs
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/bank-transactions")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/bank-transactions">
              <div className="flex flex-row gap-[12px]">
                <FontAwesomeIcon
                  icon={faCreditCard}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="bank transaction icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Bank Transactions
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/invoices")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/invoices">
              <div className="flex flex-row gap-[12px]">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="invoices icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Invoices
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/bills")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/bills">
              <div className="flex flex-row gap-[12px]">
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="bills icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Bills
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/financials")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/financials">
              <div className="flex flex-row gap-[12px]">
                <FontAwesomeIcon
                  icon={faScaleBalanced}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="financial icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Financials
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/report/")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/report/opportunity">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/reports.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="dashboard icon"
                />
                <span
                  className={`text-[#747475] hover:text-black font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Reports
                </span>
              </div>
            </Link>
          </li>
          <li
            className={`px-[16px] py-[10px] rounded-[12px] ${
              location.pathname.startsWith("/setting")
                ? "text-black bg-white"
                : "text-[#747475] hover:bg-gray-200 hover:text-black"
            }`}
          >
            <Link to="/setting">
              <div className="flex flex-row gap-[12px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/setup.png"}
                  className={`${
                    !props.isSidebarOpen ? "w-[25px]" : "w-[20px] h-[20px]"
                  }`}
                  alt="dashboard icon"
                />
                <span
                  className={`font-normal font-Inter ${
                    !props.isSidebarOpen ? "hidden" : ""
                  }`}
                >
                  Settings
                </span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
